.accordion--1cNDo {
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 10%); }
  .accordion__section--vsWTk {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0); }
    .accordion__section_expanded--1jDz_ {
      border-bottom: 1px solid hsla(0, 0%, 93%, 1); }
  .accordion__section-title--AkuRn {
    flex: 1;
    padding: 0 15px; }
  .accordion__section-icon-arrow--2vwQT {
    --svg-primary-color: hsla(0, 0%, 65%, 1); }
    .accordion__section-icon-arrow_active--3fuxh svg {
      transform: rotate(180deg);
      --svg-primary-color: hsla(0, 0%, 15%, 1); }
    .accordion__section-icon-arrow--2vwQT svg {
      transition: all 0.25s ease-in-out; }
  .accordion__option--1QjZB {
    display: none;
    flex: 1;
    padding: 0 20px; }
    .accordion__option_visible--P4BYv {
      display: block; }
  .accordion__option-content--22CEC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid hsla(0, 0%, 93%, 1); }
    .accordion__option-content_first--3uUaV {
      border-top: 0; }
  .accordion__option-describe--TnG5X {
    flex-direction: column; }
  .accordion__option-describe-decription--1b8Wv {
    color: hsla(0, 0%, 65%, 1); }
  .accordion__option-price--3EPTz {
    flex-direction: column;
    padding-left: 15px;
    text-align: end; }
  .accordion__option-price-text--2q-yU {
    white-space: nowrap; }
  .accordion__option-price-unit--1R2Q_ {
    color: hsla(0, 0%, 65%, 1); }
