@media (max-width: 989px) {
  .price-list__section--1tTzJ {
    flex-direction: column; } }

.price-list__section-info--3hiUj {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.price-list__section-title--2y_Qx {
  display: flex;
  flex-direction: column; }
  @media (min-width: 990px) {
    .price-list__section-title--2y_Qx {
      flex-direction: column-reverse; } }

.price-list__header--8F9ZW {
  margin-top: 20px;
  margin-bottom: 10px; }
  @media (min-width: 990px) {
    .price-list__header--8F9ZW {
      margin-top: 0;
      margin-bottom: 25px; } }

.price-list__picture--Hbe79 {
  border-radius: 6px; }

.price-list__section-info--3hiUj .price-list__button--2ArtT {
  display: none; }
  @media (min-width: 990px) {
    .price-list__section-info--3hiUj .price-list__button--2ArtT {
      display: flex;
      flex: 0 0 auto; } }

.price-list__options-group--2tjaa {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: space-between; }

.price-list__options-group-title--1bnWH {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  color: hsla(0, 0%, 65%, 1);
  border-bottom: 1px solid hsla(0, 0%, 93%, 1); }
  .price-list__options-group-title--addition--3DC4t {
    color: var(--color-primary); }

.price-list__option--24PSM {
  --svg-primary-color: hsla(0, 0%, 85%, 1);
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid hsla(0, 0%, 93%, 1); }
  .price-list__option--addition--2N69t {
    color: var(--color-primary); }
  @media (max-width: 989px) {
    .price-list__option--last-visible-on-mobile--1jiT8 {
      border-bottom: 0 none; }
    .price-list__option--hidden-on-mobile--5YHzM {
      display: none; } }
  @media (min-width: 990px) {
    .price-list__option--hidden-on-not-mobile--2tA35 {
      display: none; } }

.price-list__option-content--1U3Lu {
  display: flex;
  justify-content: space-between; }
  .price-list__option-content--clickable--2JdK2 {
    cursor: pointer;
    -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0); }

.price-list__option-content-text--1h9e9 {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 50px;
  overflow: hidden; }

.price-list__option-title--Os_9w {
  overflow: hidden;
  text-overflow: ellipsis; }

.price-list__option-price--2k521 {
  color: hsla(0, 0%, 65%, 1); }

.price-list__offer-description-icon--3rY84 {
  flex: 0 0;
  margin-left: 5px; }

.price-list__offer-description--1-tU- {
  display: none; }

.price-list__options-group--2tjaa .price-list__button--2ArtT {
  margin-top: 10px;
  margin-bottom: 30px; }
  @media (min-width: 990px) {
    .price-list__options-group--2tjaa .price-list__button--2ArtT {
      display: none; } }

.price-list__options-group--last-in-section--2xU62 .price-list__button--2ArtT {
  margin-bottom: 0; }
